
<template>
  <div v-if="loadUsers" class="text-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loadUsers" id="table-student">
    <table class="table caption-top table-hover table-bordered">
      <caption>
        <div class="row">
          <div class="col">Lista de Progresso Usuarios por Curso</div>

          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="listPerDate(1)">Aplicar</button>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="clearFilterDate(1)">
              Limpar
            </button>
          </div>
          <div class="col text-end">
            <input
              type="text"
              class="input-search"
              placeholder="Digite seu Filtro "
              v-model="nameSearch"
            />
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Curso</th>
          <th scope="col">Porcentagem</th>
          <th v-if="tipoIntegracao == 1" scope="col">Opções</th>
        </tr>
      </thead>
      <tbody v-if="!loadFilter">
        <tr v-for="user in users" :key="user.id">
          <th scope="row">
            <i :class="'bi-person'" style="font-size: 15px; color: #fe4a4a"></i>
          </th>
          <td>{{ user.nome ? user.nome : "Não registrado" }}</td>
          <td>{{ user.email ? user.email : "Não registrado" }}</td>
          <td>
            {{ user.curso ? user.curso : "Não registrado" }}
          </td>

          <td>{{ deixandoBonitoAPorcentagem(user.porcentagem) }}%</td>
        </tr>
      </tbody>
      <div
        v-if="loadFilter"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </table>
    <nav aria-label="..." v-if="!loadFilter">
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getProgressUsers(1)"
            >Primeira</a
          >
        </li>
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getProgressUsers(current_page - 1)"
            >Anterior</a
          >
        </li>
        <li
          v-for="index in last_page"
          v-show="
            current_page == index ||
            current_page == index - 1 ||
            current_page == index - 2 ||
            current_page == index - 3 ||
            current_page == index + 1 ||
            current_page == index + 2 ||
            current_page == index + 3
          "
          :key="index"
          :class="index == current_page ? 'active' : ''"
          class="page-item"
          aria-current="page"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getProgressUsers(index)"
            >{{ index }}</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getProgressUsers(current_page + 1)"
            >Próximo</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getProgressUsers(last_page)"
            >Último</a
          >
        </li>
      </ul>
    </nav>
    <div class="row">
      <div
        v-if="loadUsersExport"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="col" v-if="!loadUsersExport">
        <div lass="d-flex" style="gap: 5px">
          <button
            class="btn btn-outline-danger"
            v-if="!userExport"
            @click="getUsersProgressExport()"
          >
            Exportar
          </button>

          <button
            class="btn btn-outline-danger ml-5"
            @click="getUsersExportPdf()">
            <div
              v-if="loadUsersExportPdf"
              class="spinner-border text-danger"
              role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <div v-else>
              Exportar como PDF
            </div>
          </button>
        </div>
        <div class="col" id="bnt-excel" v-if="userExport">
          <vue-excel-xlsx
            :data="data"
            :columns="columns"
            :filename="'Lista de Usuarios'"
            :sheetname="'sheetname'"
          >
            <i class="bi bi-cloud-download" style="font-size: 20px"></i>
            Download
          </vue-excel-xlsx>
        </div>
      </div>
    </div>

    <modal-delete
      v-if="mostrarModal"
      :idUser="idUser"
      @close="mostrarModal = false"
      @deletar="excluirCadastro"
    />
  </div>
</template>

<script>
  import jsPDF from "jspdf";
  import "jspdf-autotable";
export default {
  components: {},
  data() {
    return {
      urlConsult: "",
      loadUsers: false,
      userExport: false,
      loadUsersExport: false,
      users: null,
      last_page: null,
      current_page: null,
      nameSearch: null,
      count: 1500,
      timeOut: null,
      loadFilter: false,
      tipoIntegracao: 0,
      startDate: null,
      endDate: null,
      columns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Curso",
          field: "curso",
        },
        {
          label: "Porcentagem",
          field: "porcentagem",
        },
      ],
      data: [],
      mostrarModal: false,
      idUser: null,
      loadUsersExportPdf: false,
    };
  },
  created() {
    this.getProgressUsers(1);
    this.getTipoIntegracao();
  },
  watch: {
    nameSearch() {
      clearTimeout(this.timeOut);
      this.loadFilter = true;
      this.timeOut = setTimeout(() => {
        if (this.nameSearch) {
          this.data = [];
          this.getUserProgress(this.nameSearch);
          this.loadFilter = false;
        } else {
          this.urlConsult = "";
          this.data = [];
          this.getProgressUsers(1);
          this.loadFilter = false;
        }
      }, this.count);
    },
  },
  methods: {
    deixandoBonitoAPorcentagem: function (valor) {
      return valor.substring(0, valor.length - 2);
    },
    statusUser(status) {
      if (status == "Em andamento") return "user-status";
      else return "user-status-done";
    },
    async getUsersProgressExport() {
      this.data = [];
      this.loadUsersExport = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url;

      if (this.urlConsult) {
        url = this.urlConsult;
        url = url.replace("?page=1", "");
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/user/progresso/progresso-por-curso-export?plataforma=" +
          plataforma;
      }

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data;

          data.forEach((element) => {
            this.data.push({
              name: element.nome,
              email: element.email,
              curso: element.curso,
              porcentagem: element.porcentagem,
            });
          });

          this.loadUsersExport = false;
          this.userExport = true;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsersExport = false;
        });
    },

    async getProgressUsers(page) {
      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.userExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/user/progresso/progresso-por-curso" +
        "?page=" +
        page +
        "&plataforma=" +
        plataforma;
      console.log(url, page, plataforma);
      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },
    async getUserProgress(filter) {
      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.userExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/user/progresso/progresso-por-curso" +
        "?page=1" +
        "&plataforma=" +
        plataforma +
        "&filtro=" +
        filter;


      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },
    editarCadastro(idUsuario) {
      if (this.tipoIntegracao == 1) {
        this.$router.replace("/edited-student/" + idUsuario);
      }
    },
    confirmarExclusao(idUsuario) {
      this.idUser = idUsuario;
      this.mostrarModal = true;
    },
    async excluirCadastro(idUserDeleted) {
      console.log(idUserDeleted);
      let token = localStorage.getItem("token");

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/user-delete/" +
        idUserDeleted;
      await this.axios
        .post(url, null, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          console.log(response);
          this.mostrarModal = false;
          this.$toast.success(`Cadastro deletado com sucesso!`);
          this.getProgressUsers(1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipoIntegracao() {
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));

      let plataforma = localStorage.getItem("plataforma");

      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          this.tipoIntegracao = element.tipoIntegracao.tipo_integracao;
        }
      });
    },

     // Gerador de PDF
     async getUsersExportPdf() {
      this.loadUsersExportPdf = true;

      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));
      let nomePlataforma = "";
      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          nomePlataforma = element.titulo;
        }
      });
 
      // Convertendo objeto nomePlataforma para string
      nomePlataforma = JSON.stringify(nomePlataforma);
      // Removendo aspas duplas e {} do nome da plataforma
      nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
      nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
      // Removendo a palavra titulo do nome da plataforma
      nomePlataforma = nomePlataforma.replace("titulo", "");
      // Tirando : do nome da plataforma
      nomePlataforma = nomePlataforma.replace(":", "");

      let url;
      let data;

      if (this.urlConsult) {
        url = this.urlConsult;
        url = url.replace("?page=1", "");
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/user/progresso/progresso-por-curso-export?plataforma=" +
          plataforma;
      }

      await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          data = response.data;
        });

      // Exportando tabela usando o jsPDF e autoTable
      let doc = new jsPDF("p", "pt", "a4");
      doc.setFontSize(18);
      doc.text(`Lista de progresso usuários por curso | ${nomePlataforma}`, 40, 85);
      doc.addImage(
        "https://lms-file-upload.s3.us-west-2.amazonaws.com/provedores/logos/20221007011553-leveduraAtivo%2014%403x.png",
        "PNG",
        40,
        25,
        165,
        32, 
      );

      //Criando a estrutura da tabela
      let columns = [
        { title: "Nome", dataKey: "nome" },
        { title: "Email", dataKey: "email" },
        { title: "Curso", dataKey: "curso" },
        { title: "Porcentagem", dataKey: "porcentagem" },
      ];

      // Formatando dados do array para o formato da tabela
      let rows = [];
      data.forEach((element) => {
        let row = {
          nome: element.nome,
          email: element.email,
          curso: element.curso,
          porcentagem: this.deixandoBonitoAPorcentagem(element.porcentagem) + "%",
        };
        rows.push(row);
      });

      console.log(rows);
      doc.autoTable(columns, rows,
        {
          headStyles: {
            fillColor: [255, 77, 77],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },

          margin: { top: 100 },
        }
      );

      // Cria uma slug do nome da plataforma para o nome do arquivo
      let slug = nomePlataforma
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      doc.save(`lista-de-progresso-de-usuarios-por-curso-${slug}.pdf`);
      this.loadUsersExportPdf = false;
    }
  },
};
</script>

<style scoped>
.user-status {
  font-weight: bold;
  color: blue;
}
.user-status-done {
  font-weight: bold;
  color: green;
}
#student {
  color: black;
}
.input-search {
  padding: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #888888;
  border-radius: 5px;
  color: black;
}
.col-dep {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: auto;
  border-radius: 10px;
  color: white;
  -webkit-box-shadow: 10px 10px 15px -2px rgba(0, 0, 0, 0.52);
  box-shadow: 10px 10px 15px -2px rgba(0, 0, 0, 0.52);
}
.tit {
  background-color: #fe4a4a;
}
.dep {
  background-color: rgb(8, 139, 8);
}
.input-field {
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #888888;
  border-radius: 5px;
}
.btn-apply {
  background: #fe4a4a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  border: none;
  padding: 5px;
}
.div-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
