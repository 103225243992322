<template>
  <div id="table-student">
    <TableStudents />
  </div>
</template>

<script>
import TableStudents from "./table-students.vue";
export default {
  data() {
    return {};
  },
  components: {
    TableStudents,
  },
};
</script>

<style scoped>
</style>