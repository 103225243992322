
<template>
    <div id="student">
        <p>Alunos - Logados</p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
#student{
    color: black;
}

</style>