import {
    createRouter,
    createWebHistory
} from 'vue-router';

import Menu from '../views/Menu.vue';

import Home from '../views/Home/Home.vue';

/*Login*/
import Login from '../views/Login.vue';

/*Estudantes*/
import NewStudents from '../components/student/New/new-students';
import LoggedStudents from '../components/student/Logged/logged-students';
import RegisteredStudents from '../components/student/Registred/registered-students';
import EditedStudents from '../components/student/Edited/edited-students';
import Report from '../components/report/report';
import Viacom from '../components/viacom/viacom';
import Information from '../components/information/information';
import ProgressUsers from "../components/progress/progress-user";
import ProgressUsersCurso from "../components/progress/progress-user-curso";


/*Cusos*/
import CursoList from "../components/cursos/CursoList/curso-list.vue";

/*Certificados*/
import CertificadoList from "../components/certificado/CertificadoEmitido/certificado-list.vue";

/*Clube*/
import ClubeList from "../components/clube/clube-list.vue";

/*Not Found*/
import NotFound from "../views/Notfound/NotFound.vue";

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/',
        name: 'Menu',
        component: Menu,
        children: [{
                path: '',
                name: 'Home',
                component: Home,
                meta: {
                    requiresAuth: true
                }
            }, {
                path: '/new-students',
                name: 'NewStudents',
                component: NewStudents,
                meta: {
                    requiresAuth: true
                }
            }, {
                path: '/logged-students',
                name: 'LoggedStudents',
                component: LoggedStudents,
                meta: {
                    requiresAuth: true
                }
            }, {
                path: '/registered-student',
                name: 'RegisteredStudents',
                component: RegisteredStudents,
                meta: {
                    requiresAuth: true
                }
            }, {
                path: '/edited-student/:id',
                name: 'EditedStudents',
                component: EditedStudents,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/list-cursos',
                name: 'CursoList',
                component: CursoList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/list-clube',
                name: 'ClubeList',
                component: ClubeList,
                meta: {
                    requiresAuth: true
                }
            },

            {
                path: '/list-certificados',
                name: 'CertificadoList',
                component: CertificadoList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/report',
                name: 'report',
                component: Report,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/viacom',
                name: 'Viacom',
                component: Viacom,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/info',
                name: 'Information',
                component: Information,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/progress-users',
                name: 'ProgressUsers',
                component: ProgressUsers,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/progress-users-curso',
                name: 'ProgressUsersCurso',
                component: ProgressUsersCurso,
                meta: {
                    requiresAuth: true
                }
            },

        ]
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL + '/#'),
    routes
})

router.beforeEach((to, from, next) => {
    var token = localStorage.token;

    if (to.matched.some((record) => record.meta.requiresAuth) && token == null) {
        next({
            name: 'Login'
        });
    } else {
        next();
    }
});

export default router