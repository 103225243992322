<template>
  <div class="modal" tabindex="-1" id="meuModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-header"
          style="padding: 1rem 1rem 0; border-bottom: 0"
        >
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <a href="https://forms.gle/n1g6TUFjyPJeucFH9" target="blank">
            <img
              src="https://s3.us-west-2.amazonaws.com/academiadoprovedor.com.br/imagens/modal-reflex/PopupAcademia.png"
              class="img-fluid"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

