<template>
    <div>
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <i class="bi bi-exclamation-triangle-fill" style="font-size: 40px;color: #f9bc07; padding-right: 5px"></i>
                            Excluir cadastro permanentemente?
                        </h5>
                        <div @click="$emit('close')" style="cursor: pointer">
                              <i class="bi bi-x" style="font-size: 40px; color: #c1c1c1"></i>  
                        </div>
                    </div>
                    <div class="modal-body">
                        <p>Após confirmar a exclusão deste cadastro, não será possível recuperar o conteúdo excluído. Para confirmar, digite 'excluir' abaixo e clique em deletar:</p>
                        <input
                            type="text"
                            class="form-control"
                            v-model="confirmMessage"
                            placeholder="excluir"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" @click="$emit('close')">Cancelar</button>
                        <button type="button" class="btn btn-danger" :disabled="confirmMessage != 'excluir'" @click="confirmarExclusao">Deletar</button>
                    </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ModalDelete',
    props: {
        idUser: Number
    },
    data() {
        return {
            confirmMessage: ''
        }
    },
    methods: {
        confirmarExclusao() {
            this.$emit('deletar', this.idUser);
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

</style>