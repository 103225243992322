import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueExcelXlsx from "vue-excel-xlsx";
import Toaster from "@meforma/vue-toaster";

/*Intercepta todas requisições caso de 401 redireciona para login e limpa o local storage*/
axios.interceptors.response.use(function (response) {
    if (response.data.hashEmpresa) {
        localStorage.setItem("hashEmpresa", response.data.hashEmpresa);
    } else {
        localStorage.removeItem("hashEmpresa");
    }
    return response;
}, function (error) {
    if (error.response.status == 401) {
        localStorage.clear();
        router.push('/login');
    } else {
        throw error;
    }
});

createApp(App)
    .use(store)
    .use(VueExcelXlsx)
    .use(Toaster, {
        // One of the options
        position: "bottom-right",
    })
    .use(VueAxios, axios)
    .use(router)
    .mixin({
        data() {
            return {
                production: true,
            }
        },
        computed: {
            VUE_APP_URL_API_DASHBOARD() {
                if (this.production) {
                    return 'https://studio-backend-desenvolvimento.leveduca.com.br';
                } else {
                    return 'http://localhost:8000';
                }
            },
        }
    })
    .mount('#app')