<template>
  <div class="container">
    <div class="card text-white mb-3" style="max-width: 18rem">
      <div class="card-header" style="color: black">{{ header }}</div>
      <div class="card-body">
        <router-link :to="to">
          <h5 class="card-title" style="font-size: 30px; color: black">
            <i
              :class="'bi-' + icon"
              style="font-size: 25px; color: #fe4a4a"
            ></i>
            {{ count }}
          </h5>
        </router-link>
      </div>
    </div>
  </div>
  <modal-delete
      v-if="mostrarModal"
      :idUser="idUser"
      @close="mostrarModal = false"
      @deletar="excluirCadastro"
    />
</template>



<script>
export default {
  data() {
    return {};
  },
  props: {
    header: String,
    count: Number,
    icon: String,
    to: String,
  },
};
</script>

<style scoped>
a{
  text-decoration: none;
}
</style>