
<template>
  <div v-if="loadClube" class="text-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loadClube && hideContent" class="text-center">Nenhum registro de acessos para o Leveduca Vantagens</div>
  <div v-if="!loadClube && !hideContent" id="table-student">
    <table class="table caption-top table-hover table-bordered">
      <caption>
        <div class="row">
          <div class="col">Lista de Acessos Leveduca Vantagens</div>
          <div class="col">
            <div class="col">
              <div class="div-date">
                <div>
                  <label for="">De</label>
                  <input
                    type="date"
                    class="input-field"
                    v-model="startDate"
                    placeholder="dd-mm-yyyy"
                    min="1997-01-01"
                    max="2030-12-31"
                  />
                </div>
                <div>
                  <label for="">Até</label>
                  <input
                    type="date"
                    class="input-field"
                    v-model="endDate"
                    placeholder="dd-mm-yyyy"
                    min="1997-01-01"
                    max="2030-12-31"
                    @keyup.enter="listPerDate(1)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="listPerDate(1)">Aplicar</button>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="getClube(1)">Limpar</button>
          </div>

          <div class="col text-end">
            <input
              type="text"
              class="input-search"
              placeholder="Digite seu Filtro"
              v-model="nameSearch"
            />
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <!-- <th scope="col">Aulas</th> -->
          <th scope="col">Documento</th>
          <th scope="col">Plataforma</th>
          <th scope="col">Serviço</th>
          <th scope="col">Acesso</th>
        </tr>
      </thead>
      <tbody v-if="!loadFilter">
        <tr v-for="clube in clubes" :key="clube.id">
          <th scope="row">
            <i
              :class="'bi bi-card-list'"
              style="font-size: 15px; color: #fe4a4a"
            ></i>
          </th>
          <td>
            {{ clube.nome ? clube.nome : "Não registrado" }}
          </td>
          <td>
            {{ clube.documento ? clube.documento : "Não registrado" }}
          </td>
          <td>
            {{ clube.plataforma ? clube.plataforma : "Não registrado" }}
          </td>
          <td>
            {{
              clube.servico == "Leveduca Vantagens"
                ? "Leveduca Vantagens"
                : "Não registrado"
            }}
          </td>
          <td>
            {{ clube.acesso ? clube.acesso : "Não registrado" }}
          </td>
        </tr>
      </tbody>
      <div
        v-if="loadFilter"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </table>
    <nav aria-label="..." v-if="!loadFilter">
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getClube(1)"
            >Primeira</a
          >
        </li>
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getClube(current_page - 1)"
            >Anterior</a
          >
        </li>
        <li
          v-for="index in last_page"
          v-show="
            current_page == index ||
            current_page == index - 1 ||
            current_page == index - 2 ||
            current_page == index - 3 ||
            current_page == index + 1 ||
            current_page == index + 2 ||
            current_page == index + 3
          "
          :key="index"
          :class="index == current_page ? 'active' : ''"
          class="page-item"
          aria-current="page"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getClube(index)"
            >{{ index }}</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getClube(current_page + 1)"
            >Próximo</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getClube(last_page)"
            >Último</a
          >
        </li>
      </ul>
    </nav>
    <div class="row">
      <div
        v-if="loadClubeExport"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="col" v-if="!loadClubeExport">
        <div class="d-flex" style="gap: 5px">
          <button
            class="btn btn-outline-danger"
            v-if="!clubeExport"
            @click="getClubeExport()"
          >
            Exportar
          </button>

          <button
            class="btn btn-outline-danger ml-5"
            @click="getUsersExportPdf()"
          >
            <div
              v-if="loadUsersExportPdf"
              class="spinner-border text-danger"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>

            <div v-else>Exportar como PDF</div>
          </button>
        </div>
        <div class="col" id="bnt-excel" v-if="clubeExport">
          <vue-excel-xlsx
            :data="data"
            :columns="columns"
            :filename="'Lista de Cursos e status'"
            :sheetname="'sheetname'"
          >
            <i class="bi bi-cloud-download" style="font-size: 20px"></i>
            Download
          </vue-excel-xlsx>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      loadClube: false,
      clubeExport: false,
      loadClubeExport: false,
      clubes: null,
      last_page: null,
      current_page: null,
      nameSearch: null,
      count: 1500,
      timeOut: null,
      loadFilter: false,
      startDate: null,
      endDate: null,
      hideContent: false,
      columns: [
        {
          label: "Nome",
          field: "nome",
        },
        {
          label: "Documento",
          field: "documento",
        },
        {
          label: "Plataforma",
          field: "plataforma",
        },
        {
          label: "Serviço",
          field: "servico",
        },
        {
          label: "Acesso",
          field: "acesso",
        },
      ],
      data: [],
      loadUsersExportPdf: false,
    };
  },
  created() {
    this.getClube(1);
  },
  watch: {
    nameSearch() {
      this.clubeExport = false;
      clearTimeout(this.timeOut);
      this.loadFilter = true;
      this.timeOut = setTimeout(() => {
        if (this.nameSearch) {
          this.startDate = this.endDate = null;
          this.getClubeFiltro(this.nameSearch);
          this.data = [];
        } else {
          this.getClube(1);
          this.data = [];
          this.loadFilter = false;
        }
      }, this.count);
    },
  },
  methods: {
    async getClubeExport() {
      this.loadClubeExport = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/clube-relatorio/" +
        plataforma;

      if (this.startDate && this.endDate) {
        url = url + "/" + this.startDate + "/" + this.endDate;
      } else if (this.nameSearch) {
        url = url + "?filtro=" + this.nameSearch;
      }

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.acessos;

          data.forEach((clube) => {
            this.data.push({
              nome: clube["nome"],
              documento: clube["documento"],
              plataforma: clube["plataforma"],
              servico: clube["servico"],
              acesso: clube["acesso"],
            });
          });
          this.clubeExport = true;
          this.loadClubeExport = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadClubeExport = false;
        });
    },
    async getClube(page) {
      this.loadClube = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/clube/" +
        plataforma +
        "?page=" +
        page;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.acessos.data;
          if(data.length == 0){
            this.hideContent = true;
          }
          this.last_page = response.data.acessos.last_page;
          this.current_page = response.data.acessos.current_page;
          this.clubes = data;

          this.startDate = null;
          this.endDate = null;

          this.loadClube = false;
        })
        .catch(() => {
          this.loadClube = false;
        });
    },
    async getClubeFiltro(filter) {
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/clube/" +
        plataforma +
        "?filtro=" +
        filter;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.acessos.data;
          this.last_page = response.data.acessos.last_page;
          this.current_page = response.data.acessos.current_page;
          this.clubes = data;
          this.loadFilter = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadFilter = false;
        });
    },
    async listPerDate(page) {
      this.data = [];
      this.loadFilter = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.clubeExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/clube/" +
        plataforma +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "?page=" +
        page;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.acessos.data;
          this.last_page = response.data.acessos.last_page;
          this.current_page = response.data.acessos.current_page;
          this.clubes = data;

          this.loadFilter = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadFilter = false;
        });
    },

    // Gerador de PDF
    async getUsersExportPdf() {
      this.loadUsersExportPdf = true;

      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));
      let nomePlataforma = "";
      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          nomePlataforma = element.titulo;
        }
      });

      // Convertendo objeto nomePlataforma para string
      nomePlataforma = JSON.stringify(nomePlataforma);
      // Removendo aspas duplas e {} do nome da plataforma
      nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
      nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
      // Removendo a palavra titulo do nome da plataforma
      nomePlataforma = nomePlataforma.replace("titulo", "");
      // Tirando : do nome da plataforma
      nomePlataforma = nomePlataforma.replace(":", "");
      let data;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/clube-relatorio/" +
        plataforma;

      if (this.startDate && this.endDate) {
        url = url + "/" + this.startDate + "/" + this.endDate;
      } else if (this.nameSearch) {
        url = url + "?filtro=" + this.nameSearch;
      }

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          data = response.data.acessos;
        });

      // Exportando tabela usando o jsPDF e autoTable
      let doc = new jsPDF("p", "pt", "a4");
      doc.setFontSize(18);
      doc.text(`Lista de Acessos Leveduca Vantagens | ${nomePlataforma}`, 40, 85);
      doc.addImage(
        "https://lms-file-upload.s3.us-west-2.amazonaws.com/provedores/logos/20221007011553-leveduraAtivo%2014%403x.png",
        "PNG",
        40,
        25,
        165,
        32
      );

      //Criando a estrutura da tabela
      let columns = [
        { title: "Nome", dataKey: "nome" },
        { title: "Documento", dataKey: "documento" },
        { title: "Plataforma", dataKey: "plataforma" },
        { title: "Serviço", dataKey: "servico" },
        { title: "Acesso", dataKey: "acesso" },
      ];

      // Formatando dados do array para o formato da tabela
      let rows = [];
      data.forEach((element) => {
        let row = {
          nome: element.nome,
          documento: element.documento,
          plataforma: element.plataforma,
          servico: element.servico,
          acesso: element.acesso,
        };
        rows.push(row);
      });

      doc.autoTable(columns, rows, {
        headStyles: {
          fillColor: [255, 77, 77],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },

        margin: { top: 100 },
      });

      // Cria uma slug do nome da plataforma para o nome do arquivo
      let slug = nomePlataforma
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      doc.save(`lista-de-acessos-clube-leveduca-${slug}.pdf`);
      this.loadUsersExportPdf = false;
    },
  },
};
</script>

<style scoped>
#student {
  color: black;
}
.input-search {
  padding: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #888888;
  border-radius: 5px;
  color: black;
}

.div-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input-field {
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #888888;
  border-radius: 5px;
}

.btn-apply {
  background: #fe4a4a 0%0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  border: none;
  padding: 5px;
}
</style>