
<template>
  <div class="container text-center">
    <!-- <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div> -->
<modal-pesquisa/>
    <div class="pt-4 pb-4">
      <p>
        Olá <strong>{{ user.nome ? user.nome : "Cliente" }}</strong
        >, seja bem vindo(a) ao Reflex,painel de indicadores da sua plataforma
        <a href="https://leveduca.com.br" target="_blank"
          ><strong style="font-size: 24px"
            ><span class="lev-text">LEV</span>EDUCA</strong
          ></a
        >.
      </p>
    </div>
    <div v-if="loadDataCount" class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="row" v-if="!loadDataCount">
      <div class="col-md-6" v-if="counts.contractPlan">
        <Card
          header="Plano Contratado"
          :count="counts.contractPlan"
          icon="people"
          to="#"
        />
      </div>
      <div class="col-md-6">
        <Card
          header="Alunos Principais"
          :count="counts.users"
          icon="person-circle"
          to="new-students"
        />
      </div>
      <div class="col-md-6" v-if="mostrarDependente">
        <Card
          header="Alunos Dependentes"
          :count="counts.dependentes <= 0 ? 0 : counts.dependentes"
          icon="person-circle"
          to="new-students"
        />
      </div>
      <div class="col-md-6">
        <Card
          header="Cursos"
          :count="counts.cursos"
          icon="book"
          to="list-cursos"
        />
      </div>      
      <div class="col-md-6">
        <Card
          header="Certificados Emitidos"
          :count="counts.certificados"
          icon="mortarboard"
          to="list-certificados"
        />
      </div>
    </div>
  </div>
  
</template>

<script>
import Card from "../card/card.vue";
import modalPesquisa from "../home/modalPesquisa/modalPesquisa.vue";
export default {
  components: {
    Card,
    modalPesquisa,
  },
  data() {
    return {
      user: null,
      loadProvider: false,
      loadDataCount: false,
      tokenApiNubbi: null,
      apimk: null,
      mostrarDependente : false,
      slug: '',
      counts: {
        users: null,
        cursos: null,
        certificados: null,
        contractPlan: null,
        dependentes: null,
      },
    };
  },
  created() {
    this.user = this.me();
    this.loadCounts();
  },
  methods: {
    me() {
      return JSON.parse(localStorage.getItem("user"));
    },
    async loadCounts() {
      this.loadProvider = true;
      this.loadDataCount = true;
      let token = localStorage.getItem("token");
      let plataforma = localStorage.getItem("plataforma");

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/" + plataforma;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => { 
          this.counts.users = response.data.users;
          this.counts.cursos = response.data.cursos;
          this.counts.contractPlan = response.data.qtd_usuario;
          this.counts.dependentes = response.data.dependentes;

          if(response.data.conta_empresa){
            this.counts.users = response.data.users -= 1 ;
          }
          this.slug = response.data.slug;
          this.apimk = response.data.apimk ? response.data.apimk.nome_provedor_apimk : null ;
          this.mostrarDependente = response.data.qtd_dependentes_cpf == 0 ? false : true;

          if(this.apimk === 'fenixsolutions'){
            this.counts.users -= 44; // Solicitaram para não mostrar os usuários moodle, tratar isso no back depois
          }

          if(this.apimk === 'flixtelecom'){
            this.counts.users -= 238;
          }

          this.counts.certificados = response.data.certificados;
          this.loadDataCount = false;
        })
        .catch((error) => {
          
          console.log(error);

          this.loadDataCount = false;
        });
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.container a{
  text-decoration: none;
  color: black;
}
.lev-text {
  background: #fe4a4a;
  color: white;
  border-radius: 8px 8px 0 8px;
  padding: 5px;
}
</style>