<template>
    <div class="div-principal">

        <div class="botoes">

            <div class="botaoVagasClipBoard">
                <a href="https://bit.ly/tutorialleveduca" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Tutoriais da Leveduca</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso1', 'https://bit.ly/tutorialleveduca')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso1'" class="linkCopiado"><span class="textoLinkCopiado"> Link Copiado!
                    </span></div>
            </div>

            <div class="botaoVagasClipBoard">
                <a href="https://chat.whatsapp.com/JjtAsB9il5Z3iw49O9TE3W" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Grupo do WhatsApp da LEVEDUCA</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso2', 'https://chat.whatsapp.com/JjtAsB9il5Z3iw49O9TE3W')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso2'" class="linkCopiado"><span class="textoLinkCopiado"> Link Copiado!
                    </span></div>
            </div>

            <div class="botaoVagasClipBoard">
                <a href="https://wa.me/5535910178503" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Cobranças e Assuntos Financeiros</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso3', 'https://wa.me/5535910178503')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso3'" class="linkCopiado"><span class="textoLinkCopiado"> Link Copiado!
                    </span></div>
            </div>

        </div>

        <!-- botoes meio-->
        <div class="botoes">

            <div class="botaoVagasClipBoard">
                <a href="https://tally.so/r/mZj8jv" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Cadastro de Vagas</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso4', 'https://tally.so/r/mZj8jv')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso4'" class="linkCopiado"><span class="textoLinkCopiado"> Link Copiado!
                    </span></div>
            </div>

            <div class="botaoVagasClipBoard">
                <a href="https://nubbileveduca-my.sharepoint.com/:f:/g/personal/tamiris_goncalves_leveduca_com_br/EkNc7-_oHU1HtGVeAl2UQsABcDp3v3nZoaZX1khvwD0Jiw?e=tDY5bf" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Arquivos para empresa</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso5', 'https://nubbileveduca-my.sharepoint.com/:f:/g/personal/tamiris_goncalves_leveduca_com_br/EkNc7-_oHU1HtGVeAl2UQsABcDp3v3nZoaZX1khvwD0Jiw?e=tDY5bf')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso5'" class="linkCopiado"><span class="textoLinkCopiado"> Link Copiado!
                    </span></div>
            </div>

            <div class="botaoVagasClipBoard">
                <a href="https://bit.ly/artejorgebarcelos" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Artes do Jorge Barcelos</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso7', 'https://bit.ly/artejorgebarcelos')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso7'" class="linkCopiado">
                    <span class="textoLinkCopiado">
                        Link Copiado!
                    </span>
                </div>
            </div>

        </div>

        <div class="botoes">
            <div class="botaoVagasClipBoard">
                <a href="https://tally.so/r/wM1lQg" target="_blank"><button id="botaoVagas"
                        class="btn btn-outline-danger button-style">Apadrinhamento</button></a>
                <ToolTip descricao="Copiar">
                    <a id="botaoClipBoard" class="btn btn-outline-danger button-style"
                        @click="copyText('aviso8', 'https://tally.so/r/wM1lQg')">
                        <span class="clipboard"><i class="fas fa-link"></i></span>
                    </a>
                </ToolTip>
                <div v-if="flagLink === 'aviso8'" class="linkCopiado">
                    <span class="textoLinkCopiado"> Link Copiado! </span>
                </div>
            </div>
            <div class="botaoVagasClipBoard">
                <button id="botaoRedes"
                    class="btn btn-outline-danger button-style"
                    @click="irPara()" 
                    >Emitir 2ª Via de Boleto
                </button>
                <div v-if="flagLink === 'aviso9'" class="linkCopiado">
                    <span class="textoLinkCopiado"> Link Copiado! </span>
                </div>
            </div>
        </div>

        <!-- botoes fim -->
        <hr class="hr hr-blurry" />
        <div class="botoes">
            <div class="botaoRedesSociais">
                <a href="https://www.youtube.com/@Leveduca" target="_blank">
                    <button id="botaoRedes" class="btn btn-outline-danger button-style">
                        <i class="fa-brands fa-youtube"></i> 
                        Youtube
                    </button>
                </a>
            </div>
            <div class="botaoRedesSociais">
                <a href="https://www.instagram.com/leveduca/" target="_blank">
                    <button id="botaoRedes" class="btn btn-outline-danger button-style">
                        <i class="fa-brands fa-instagram"></i> 
                        Instagram
                    </button>
                </a>
            </div>
            <div class="botaoRedesSociais">
                <a href="https://www.linkedin.com/company/leveduca/" target="_blank">
                    <button id="botaoRedes" class="btn btn-outline-danger button-style">
                        <i class="fa-brands fa-linkedin"></i> 
                        LinkedIn
                    </button>
                </a>
            </div>
        </div>

    </div>

</template>

<script>

import ToolTip from '../../components/tooltip/ToolTip.vue'

export default {
    data() {

        return {
            cadastrarVaga: 'https://app.pipefy.com/public/form/E52IF3sT',
            flagLink: false,
            hashEmpresa: 'https://academiadoprovedor.com.br/login',
        };
    },
    components: {
        ToolTip
    },
    methods: {
        copyText(nome, url) {
            navigator.clipboard.writeText(url);
            this.flagLink = nome
            setTimeout(() => {
                this.flagLink = ''
            }, 1500);
        },
        irPara(){
            let hash = localStorage.getItem("hashEmpresa");
            if(hash){
                this.hashEmpresa = `https://academiadoprovedor.com.br/login-token/${hash}`;
            }
            window.open(this.hashEmpresa, true);
        }
    },
    created(){
        
    },
};
</script>

<style scoped>
.div-principal {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.div-legenda-banner-show-de-premios {
    position: relative;
    font-size: 18px;
}

.button-style {
    margin-right: 10px;
}

.clipboard {
    color: black;
}

.clipboard:hover {
    color: white;
}

.botaoVagasClipBoard {
    display: flex;
    margin-right: 5px;
    /* background: orange; */
    /* background-color: orange; */
}

.botaoRedesSociais {
    display: flex;
    margin-right: 15px;
    /* background: orange; */
    /* background-color: orange; */
}

.botoes {
    display: flex;
    justify-content: start;
    height: 100%;
    /* background: green; */
}

#botaoClipBoard {
    border-radius: 0px 5px 5px 0px;
    /* background-color: aqua; */
}

#botaoVagas {
    margin-right: 0;
    border-radius: 5px 0px 0px 5px;
}

#botaoRedes {
    margin-right: 0;
    border-radius: 5px 5px 5px 5px;
}

.linkCopiado {
    color: red;
    text-align: center;
    align-items: center;
    height: 1.5rem;

}

.textoLinkCopiado {
    font-size: 12px;
}
</style>