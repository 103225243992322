<template>
    <div class="container">
        <p>
            Gerar seus relatórios
        </p>
        <div>
            <div>
                <button
                    class="btn btn-outline-danger"
                    v-if="!reportExport"
                    @click="getRelatorio()"
                >
                    <span v-if="carregarRelatorio" class="spinner-border text-danger" role="status"></span>
                    <span v-else>Exportar</span>   
                </button>
            </div>
            <div class="col" id="bnt-excel" v-if="reportExport">
                <vue-excel-xlsx
                    :data="data"
                    :columns="columns"
                    :filename="'Relatório geral'"
                    :sheetname="'sheetname'"
                >
                    <i class="bi bi-cloud-download" style="font-size: 20px"></i>
                    Download
                </vue-excel-xlsx>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Report',
    data() {
        return {
            carregarRelatorio: false,
            reportExport: false,
            data: [],
            columns: [
                {
                    label: "Provedor",
                    field: "provedor",
                },
                {
                    label: "Títulares",
                    field: "usuarioTitular",
                },
                {
                    label: "Dependentes",
                    field: "usuarioDependente",
                },
                {
                    label: "Cursos",
                    field: "qtd_cursos",
                },
                {
                    label: "Cerificados",
                    field: "qtd_certificado",
                }
            ]
        }
    },
    methods: {
        async getRelatorio() {
            this.carregarRelatorio = true
            let token = localStorage.getItem("token");

            let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/transaction/general";

            await this.axios
                    .get(url, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    })
                    .then((response) => {
                        let data = response.data;
                        console.log(data);
                        data.forEach(element => {
                            console.log(element);
                            this.data.push({
                                provedor: element.titulo,
                                qtd_cursos: element.Cursos,
                                usuarioTitular: element.usuariosPrincipais,
                                usuarioDependente: element.usuariosDependentes,
                                qtd_certificado: element.Certificados
                            })
                        });
                        
                        
                        this.reportExport = true;
                        this.carregarRelatorio = false

                    })
                    .catch((error) => {
                        console.log(error);
                        this.reportExport = false;
                        this.carregarRelatorio = false;
                    });
            

        }
    }
}
</script>

<style scoped>
    
</style>