<template>
  <main class="form-signin">
    <form>
      <h1 class="h3 mb-3 fw-normal">Reflex</h1>

      <img
        class="mb-4"
        src="../assets/logo-leveduca.png"
        alt=""
        width="172"
        height="40"
      />

      <div class="form-floating">
        <input
          v-model="form.email"
          type="email"
          class="form-control"
          id="floatingInput"
          placeholder="name@example.com"
        />
        <label for="floatingInput">Email</label>
      </div>
      <div class="form-floating">
        <input
          v-model="form.password"
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Password"
          @keyup.enter="logar()"
        />
        <label for="floatingPassword">Senha</label>
      </div>
      <a
        class="w-100 btn btn-lg btn-primary"
        :class="!form.email ? 'disabled' : ''"
        type="submit"
        @click="logar()"
      >
        <span v-if="!loadLogin">Logar</span>
        <div v-if="loadLogin" class="spinner-grow text-light" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </a>
      <p class="mt-5 mb-3 text-muted">Nubbi {{ current_year }}&reg;</p>
    </form>
  </main>
</template>


<script>
export default {
  data() {
    return {
      current_year: null,
      loadLogin: false,
      plataformas: [],
      form: {
        email: null,
        password: null,
      },
    };
  },
  // mounted() {
  //   console.log(this.VUE_APP_URL_API_DASHBOARD)
  // },
  components: {},
  created() {
    let date = new Date();
    this.current_year = date.getFullYear();
  },
  methods: {
    async logar() {
      this.loadLogin = true;

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/auth/loginReflex";
      await this.axios
        .post(url, this.form)
        .then(async (response) => {
          localStorage.clear();
          await localStorage.setItem("token", response.data.access_token);

          if (localStorage.getItem("token") != null) {
            await this.loadUser(response.data.access_token);

            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl);
            } else {
              this.$emit("login");
              this.$router.push("/");
            }
          } else {
            this.loadLogin = false;
          }

          this.loadLogin = false;
        })
        .catch(() => {
          {
            {
              this.$toast.error(
                `Usuario ou Senha incorretos, verifique e tente novamente`
              );
            }
          }
          this.loadLogin = false;
        });
    },
    async loadUser() {
      const url = this.VUE_APP_URL_API_DASHBOARD + "/api/auth/me";
      let token = localStorage.getItem("token");

      await this.axios
        .post(url, this.form, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(async (response) => {
          await localStorage.setItem(
            "user",
            JSON.stringify(response.data.informacoes_usuario.id)
          );
          localStorage.setItem(
            "permissoes",
            JSON.stringify(response.data.informacoes_usuario.permissoes)
          );

          await this.getPlataformas();
        })
        .catch(() => {
        });
    },

    async getPlataformas() {
      let token = localStorage.getItem("token");

      let user = JSON.parse(localStorage.getItem("user"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/plataformas/" + user;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(async (response) => {
          let data = response.data;
          data.forEach((element) => {
            this.plataformas.push({
              id: element.plataforma,
              titulo: element.titulo[0],
              tipoIntegracao: element.tipo_integracao[0],
            });
          });
        })
        .catch(() => {
        });
      await localStorage.setItem("plataformas", JSON.stringify(this.plataformas));
      await localStorage.setItem("plataforma", this.plataformas[0].id);
    },
  },
};
</script>

<style scoped>
.form-signin {
  text-align: center;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  padding-top: 10%;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>