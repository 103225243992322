
<template>
  <div v-if="loadCursos" class="text-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loadCursos" id="table-student">
    <table class="table caption-top table-hover table-bordered">
      <caption>
        <div class="row">
          <div class="col">Lista de Cursos</div>
          <div class="col text-end">
            <!-- <input
              type="text"
              class="input-search"
              placeholder="Digite seu Filtro"
              v-model="nameSearch"
            /> -->
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <!-- <th scope="col">Aulas</th> -->
          <!-- <th scope="col">Status</th> -->
        </tr>
      </thead>
      <tbody v-if="!loadFilter">
        <tr v-for="curso in cursos" :key="curso.id">
          <th scope="row">
            <i :class="'bi-book'" style="font-size: 15px; color: #fe4a4a"></i>
          </th>
          <td>
            {{
              curso.titulo
                ? curso.titulo
                : "Não registrado"
            }}
          </td>
          <!-- <td>{{ curso.quantidade_de_aulas ? curso.quantidade_de_aulas : "Não registrado" }}</td> -->
          <!-- <td>
            {{
              curso.status.nome
                ? curso.status.nome
                : "Não registrado"
            }}
          </td> -->
        </tr>
      </tbody>
      <div
        v-if="loadFilter"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </table>
    <nav aria-label="..." v-if="!loadFilter">
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getCursos(1)"
            >Primeira</a
          >
        </li>
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getCursos(current_page - 1)"
            >Anterior</a
          >
        </li>
        <li
          v-for="index in last_page"
          v-show="
            current_page == index ||
            current_page == index - 1 ||
            current_page == index - 2 ||
            current_page == index - 3 ||
            current_page == index + 1 ||
            current_page == index + 2 ||
            current_page == index + 3
          "
          :key="index"
          :class="index == current_page ? 'active' : ''"
          class="page-item"
          aria-current="page"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getCursos(index)"
            >{{ index }}</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getCursos(current_page + 1)"
            >Próximo</a
          >
        </li>
        <li
          class="page-item"
          :class="current_page == last_page ? 'disabled' : ''"
        >
          <a
            class="page-link"
            style="background-color: #fe4a4a; color: #fff"
            @click="getCursos(last_page)"
            >Último</a
          >
        </li>
      </ul>
    </nav>
    <div class="row">
      <div
        v-if="loadCursosExport"
        class="spinner-border text-danger mt-3"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="col" v-if="!loadCursosExport">
        <div class="d-flex" style="gap: 5px">
          <button
            class="btn btn-outline-danger"
            v-if="!cursoExport"
            @click="getCursosExport()"
          >
            Exportar
          </button>

          <button
            class="btn btn-outline-danger ml-5"
            @click="getUsersExportPdf()">
            <div
              v-if="loadUsersExportPdf"
              class="spinner-border text-danger"
              role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <div v-else>
              Exportar como PDF
            </div>
          </button>
        </div>
        <div class="col" id="bnt-excel" v-if="cursoExport">
          <vue-excel-xlsx
            :data="data"
            :columns="columns"
            :filename="'Lista de Cursos e status'"
            :sheetname="'sheetname'"
          >
            <i class="bi bi-cloud-download" style="font-size: 20px"></i>
            Download
          </vue-excel-xlsx>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      loadCursos: false,
      cursoExport: false,
      loadCursosExport: false,
      cursos: null,
      last_page: null,
      current_page: null,
      nameSearch: null,
      count: 1500,
      timeOut: null,
      loadFilter: false,
      columns: [
        {
          label: "Nome",
          field: "name",
        },
      ],
      data: [],
      loadUsersExportPdf: false,
    };
  },
  created() {
    this.getCursos(1);
  },
  watch: {
    nameSearch() {
      clearTimeout(this.timeOut);
      this.loadFilter = true;
      this.timeOut = setTimeout(() => {
        this.getCurso(this.nameSearch);
        this.loadFilter = false;
      }, this.count);
    },
  },
  methods: {
    async getCursosExport() {
      this.loadCursosExport = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/cursos-export/" +
        plataforma;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {               
          let data = response.data;

          data.forEach((curso) => {
  
            this.data.push({
              name: curso['titulo'],
            });
          });
          this.cursoExport = true;
          this.loadCursosExport = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadCursosExport = false;
        });
    },
    async getCursos(page) {
      this.loadCursos = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/cursos/" +
        plataforma +
        "?page=" +
        page;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;

          console.log(data);

          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.cursos = data;
          this.loadCursos = false;
        })
        .catch(() => {
         
          this.loadCursos = false;
        });
    },
    async getCurso(filter) {
      this.loadCursos = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/users/" +
        plataforma +
        "/" +
        filter +
        "?page=1";

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.cursos = data;

          this.loadCursos = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadCursos = false;
        });
    },

    // Gerador de PDF
    async getUsersExportPdf() {
      this.loadUsersExportPdf = true;

      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));
      let nomePlataforma = "";
      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          nomePlataforma = element.titulo;
        }
      });
 
      // Convertendo objeto nomePlataforma para string
      nomePlataforma = JSON.stringify(nomePlataforma);
      // Removendo aspas duplas e {} do nome da plataforma
      nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
      nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
      // Removendo a palavra titulo do nome da plataforma
      nomePlataforma = nomePlataforma.replace("titulo", "");
      // Tirando : do nome da plataforma
      nomePlataforma = nomePlataforma.replace(":", "");
      let data;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/cursos-export/" +
        plataforma;

      await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          data = response.data;
        });

        console.log(data);

      // Exportando tabela usando o jsPDF e autoTable
      let doc = new jsPDF("p", "pt", "a4");
      doc.setFontSize(18);
      doc.text(`Lista de cursos | ${nomePlataforma}`, 40, 85);
      doc.addImage(
        "https://lms-file-upload.s3.us-west-2.amazonaws.com/provedores/logos/20221007011553-leveduraAtivo%2014%403x.png",
        "PNG",
        40,
        25,
        165,
        32, 
      );

      //Criando a estrutura da tabela
      let columns = [
        { title: "Nome", dataKey: "nome" },
      ];

      // Formatando dados do array para o formato da tabela
      let rows = [];
      data.forEach((element) => {
        let row = {
          nome: element.titulo,
        };
        rows.push(row);
      });

      doc.autoTable(columns, rows,
        {
          headStyles: {
            fillColor: [255, 77, 77],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },

          margin: { top: 100 },
        }
      );

      // Cria uma slug do nome da plataforma para o nome do arquivo
      let slug = nomePlataforma
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      doc.save(`lista-de-cursos-${slug}.pdf`);
      this.loadUsersExportPdf = false;
    }
  },
};
</script>

<style scoped>
#student {
  color: black;
}
.input-search {
  padding: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #888888;
  border-radius: 5px;
  color: black;
}
</style>