
<template>
  <div v-if="loadCertificados" class="text-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-if="!loadCertificados" id="table-student table-responsive">
    <table class="
        table
        align-middle
        caption-top
        table-hover table-bordered table-bordered
      ">
      <caption>
        <div class="row">
          <div class="col">Lista de Certificados</div>
          <div class="col">
            <div class="col">
              <div class="div-date">
                <div>
                  <label for="">De</label>
                  <input class="input-field" type="date" v-model="startDate" placeholder="dd-mm-yyyy" min="1997-01-01"
                    max="2030-12-31" />
                </div>
                <div>
                  <label for="">Até</label>
                  <input class="input-field" type="date" v-model="endDate" placeholder="dd-mm-yyyy" min="1997-01-01"
                    max="2030-12-31" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="listPerDate(1)">Aplicar</button>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="clearFilterDate(1)">
              Limpar
            </button>
          </div>
          <div class="col text-end">
            <input type="text" class="input-search" placeholder="Digite seu Filtro" v-model="nameSearch" />
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Documento</th>
          <th scope="col">Curso</th>
          <th scope="col">Código</th>
        </tr>
      </thead>
      <tbody v-if="!loadFilter">
        <tr v-for="certificado in certificados" :key="certificado.id">
          <th scope="row">
            <i :class="'bi-mortarboard'" style="font-size: 15px; color: #fe4a4a"></i>
          </th>
          <td>
            {{ certificado.nome ? certificado.nome : "Não registrado" }}
          </td>
          <!-- <td>{{ certificado.titulo[0].quantidade_de_aulas ? certificado.titulo[0].quantidade_de_aulas : "Não registrado" }}</td> -->
          <td>
            {{ certificado.email ? certificado.email : "Não registrado" }}
          </td>
          <td>
            {{
              certificado.documento
              ? formataCPF(certificado.documento)
              : "Não registrado"
            }}
          </td>
          <td>
            {{ certificado.titulo ? certificado.titulo : "Não registrado" }}
          </td>
          <td>
            {{ certificado.codigo ? certificado.codigo : "Não registrado" }}
          </td>
        </tr>
      </tbody>
      <div v-if="loadFilter" class="spinner-border text-danger mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </table>
    <nav aria-label="..." v-if="!loadFilter">
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff" @click="getCertificados(1)">Primeira</a>
        </li>
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff"
            @click="getCertificados(current_page - 1)">Anterior</a>
        </li>
        <li v-for="index in last_page" v-show="current_page == index ||
          current_page == index - 1 ||
          current_page == index - 2 ||
          current_page == index - 3 ||
          current_page == index + 1 ||
          current_page == index + 2 ||
          current_page == index + 3
          " :key="index" :class="index == current_page ? 'active' : ''" class="page-item" aria-current="page">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff" @click="getCertificados(index)">{{ index
          }}</a>
        </li>
        <li class="page-item" :class="current_page == last_page ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff"
            @click="getCertificados(current_page + 1)">Próximo</a>
        </li>
        <li class="page-item" :class="current_page == last_page ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff"
            @click="getCertificados(last_page)">Último</a>
        </li>
      </ul>
    </nav>
    <div class="row">
      <div v-if="loadCertificadosExport" class="spinner-border text-danger mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="col" v-if="!loadCertificadosExport">
        <div class="d-flex" style="gap: 5px">
          <button class="btn btn-outline-danger" v-if="!certificadoExport" @click="getCertificadosExport()">
            Exportar
          </button>

          <button class="btn btn-outline-danger ml-5" @click="getUsersExportPdf()">
            <div v-if="loadUsersExportPdf" class="spinner-border text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <div v-else>
              Exportar como PDF
            </div>
          </button>

          <button class="btn btn-outline-danger ml-5" @click="verificaServico()">
            <div v-if="emMassaLoader" class="d-flex align-items-center gap-2">
              <span v-if="quantidadeArquivos > 0">Gerando e baixando {{ quantidadeArquivos }} arquivos, isso pode levar um
                tempo</span>
              <div class="spinner-border text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <div v-else>
              Baixar certificados em massa
            </div>
          </button>
        </div>

        <div class="col" id="bnt-excel" v-if="certificadoExport">
          <vue-excel-xlsx :data="data" :columns="columns" :filename="'Lista de Certificados emitidos'"
            :sheetname="'sheetname'">
            <i class="bi bi-cloud-download" style="font-size: 20px"></i>
            Download
          </vue-excel-xlsx>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import s3 from './../../../aws/config'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      urlConsult: "",
      loadCertificados: false,
      certificadoExport: false,
      loadCertificadosExport: false,
      certificados: null,
      last_page: null,
      current_page: null,
      nameSearch: null,
      count: 1500,
      timeOut: null,
      loadFilter: false,
      startDate: null,
      endDate: null,
      columns: [
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Documento",
          field: "document",
        },
        {
          label: "Curso",
          field: "course",
        },
        {
          label: "Código",
          field: "code",
        },
        {
          label: "Data de emissão",
          field: "date",
        },
      ],
      data: [],
      loadUsersExportPdf: false,
      emMassaLoader: false,
      certificadosDownload: [],
      quantidadeArquivos: 0,
      quantidadeArquivosLoop: 0,
      lastPage: 0
    };
  },
  created() {
    this.getCertificados(1);
  },
  watch: {
    nameSearch() {
      clearTimeout(this.timeOut);
      this.loadFilter = true;
      this.timeOut = setTimeout(() => {
        if (this.nameSearch) {
          this.data = [];
          this.certificadoExport = false;
          this.getCertificado(this.nameSearch);
          this.loadFilter = false;
        } else {
          this.urlConsult = "";
          this.certificadoExport = false;
          this.data = [];
          this.getCertificados(1);
          this.loadFilter = false;
        }
      }, this.count);
    },
  },
  methods: {
    async getCertificadosExport() {
      this.data = [];
      this.loadCertificadosExport = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url;

      if (this.urlConsult) {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/certificados-export/" +
          plataforma + "/" + this.startDate + "/" + this.endDate;
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/certificados-export/" +
          plataforma;
      }

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data;

          data.forEach((element) => {
            this.data.push({
              name: element.nome,
              email: element.email,
              document: element.documento,
              course: element.titulo,
              code: element.codigo,
              date: this.formataData(element.created_at)
            });
          });

          this.loadCertificadosExport = false;
          this.certificadoExport = true;
        })
        .catch(() => {
          this.loadCertificadosExport = false;
        });
    },
    formataCPF(cpf) {
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, "");

      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    async clearFilterDate(page) {
      this.startDate = null;
      this.endDate = null;

      this.nameSearch = "";

      this.certificadoExport = false;
      this.data = [];

      this.urlConsult = "";

      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/certificados/" +
        plataforma +
        "?page=" +
        page;

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          this.loadUsers = false;
        })
        .catch(() => {
          this.loadUsers = false;
        });
    },
    async getCertificados(page) {
      this.data = [];
      this.loadCertificados = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/certificados/" +
        plataforma +
        "?page=" +
        page;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;

          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.certificados = data;

          this.loadCertificados = false;
        })
        .catch(() => {
          this.loadCertificados = false;
        });
    },
    async getCertificado(filter) {
      this.data = [];
      this.loadCertificados = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.certificadoExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/certificados/" +
        plataforma +
        "/" +
        filter +
        "?page=1";

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.certificados = data;

          this.loadCertificados = false;
        })
        .catch(() => {
          this.loadCertificados = false;
        });
    },
    async listPerDate(page) {
      this.data = [];
      this.loadCertificados = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.certificadoExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/certificados/" +
        plataforma +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "?page=" +
        page;

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.certificados = data;

          this.loadCertificados = false;
        })
        .catch(() => {
          //console.log(error.response);
          this.loadCertificados = false;
        });
    },
    formataData(data) {
      let dataFormatada = data.substr(0, 10).split('-');
      return dataFormatada[2] + "/" + dataFormatada[1] + "/" + dataFormatada[0];
    },
    // Gerador de PDF
    async getUsersExportPdf() {
      this.loadUsersExportPdf = true;

      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));
      let nomePlataforma = "";
      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          nomePlataforma = element.titulo;
        }
      });

      // Convertendo objeto nomePlataforma para string
      nomePlataforma = JSON.stringify(nomePlataforma);
      // Removendo aspas duplas e {} do nome da plataforma
      nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
      nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
      // Removendo a palavra titulo do nome da plataforma
      nomePlataforma = nomePlataforma.replace("titulo", "");
      // Tirando : do nome da plataforma
      nomePlataforma = nomePlataforma.replace(":", "");

      let url;
      let data;

      if (this.urlConsult) {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/certificados-export/" +
          plataforma + "/" + this.startDate + "/" + this.endDate;
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/certificados-export/" +
          plataforma;
      }

      await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          data = response.data;
        });

      // Exportando tabela usando o jsPDF e autoTable
      let doc = new jsPDF("p", "pt", "a4");
      doc.setFontSize(18);
      doc.text(`Lista de certificados | ${nomePlataforma}`, 40, 85);
      doc.addImage(
        "https://lms-file-upload.s3.us-west-2.amazonaws.com/provedores/logos/20221007011553-leveduraAtivo%2014%403x.png",
        "PNG",
        40,
        25,
        165,
        32,
      );

      //Criando a estrutura da tabela
      let columns = [
        { title: "Nome", dataKey: "nome" },
        { title: "Documento", dataKey: "documento" },
        { title: "Curso", dataKey: "curso" },
        { title: "Data de Emissão", dataKey: "emissao" },
        { title: "Código", dataKey: "codigo" }
      ];

      // Formatando dados do array para o formato da tabela
      let rows = [];
      data.forEach((element) => {
        let row = {
          nome: element.nome,
          documento: element.documento,
          curso: element.titulo,
          codigo: element.codigo,
          emissao: this.formataData(element.created_at)
        };
        rows.push(row);
      });

      doc.autoTable(columns, rows,
        {
          headStyles: {
            fillColor: [255, 77, 77],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },

          margin: { top: 100 },
        }
      );

      // Cria uma slug do nome da plataforma para o nome do arquivo
      let slug = nomePlataforma
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      doc.save(`lista-de-certificados-${slug}.pdf`);
      this.loadUsersExportPdf = false;
    },
    async verificaServico() {
      this.emMassaLoader = true;
      let token = localStorage.getItem("token");

      var options = {
        method: 'GET',
        url: `${this.VUE_APP_URL_API_DASHBOARD}/api/verifica-servico`,
        headers: {
          Authorization: "Bearer " + token,
        }
      };

      await this.axios.request(options).then(() => {
        this.filtroDownload();
      }).catch(() => {
        this.$toast.warning(`Serviço em uso, tente novamente mais tarde.`);
        this.emMassaLoader = false;
      });
    },
    async filtroDownload() {
      let inicio = null;
      let final = null;

      Swal.fire({
        title: 'Selecionar certificados por período?',
        html:
          '<label>De:</label>' +
          '<input id="start-date" type="date" class="form-control mb-2" required>' +
          '<label>Até:</label>' +
          '<input id="end-date" type="date" class="form-control" required>',
        focusConfirm: false,
        showCancelButton: true,
        showDenyButton: true,
        allowOutsideClick: false,
        cancelButtonText: 'Cancelar',
        denyButtonText: 'Continuar sem o filtro',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#17a2b8',
        cancelButtonColor: '#d33',
        preConfirm: () => {
          return [
            inicio = document.getElementById('start-date').value,
            final = document.getElementById('end-date').value,
          ];
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (!inicio && !final) {
            Swal.fire({
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Confirmar',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              title: 'Cuidado!',
              text: 'Preencha as datas para usar o filtro, deseja continuar sem as datas?',
              icon: 'info'
            }
            ).then((result) => {
              if (result.isConfirmed) {
                this.quantidadeRemessaDeArquivo();
              }
            });
          } else {
            this.quantidadeRemessaDeArquivo(inicio, final);
          }
        }

        if (result.isDenied) {
          this.quantidadeRemessaDeArquivo();
        }
      });

      this.emMassaLoader = false;
    },
    async quantidadeRemessaDeArquivo(inicio = null, final = null) {
      this.emMassaLoader = true;
      let token = localStorage.getItem("token");
      let plataforma = localStorage.getItem("plataforma");
      let url = '';

      if (inicio && final) {
        if (inicio > final) {
          this.$toast.warning(`A data de início não pode ser maior que a data final.`);  
          this.emMassaLoader = false;
          return;  
        }

        url = `${this.VUE_APP_URL_API_DASHBOARD}/api/quantidade-remessa-de-arquivos/${plataforma}?inicio=${inicio}&final=${final}`;
        this.$toast.info('Filtrando os certificados.');  
      } else {
        url = `${this.VUE_APP_URL_API_DASHBOARD}/api/quantidade-remessa-de-arquivos/${plataforma}`;
        this.$toast.info('Buscando os certificados.'); 
      }

      var options = {
        method: 'GET',
        url: url,
        headers: {
          Authorization: "Bearer " + token,
        }
      };

      await this.axios.request(options).then((response) => {
        this.quantidadeArquivos = response.data.total;
        this.quantidadeArquivosLoop = response.data.total;
        this.lastPage = response.data.lastPage;

        if (this.quantidadeArquivos === 0) {
          this.emMassaLoader = false;
          return Swal.fire({
            icon: 'error',
            title: 'Nenhum certificado encontrado!',
            showConfirmButton: true,
          });
        } else {
          this.pegarLinks(inicio, final);
        }
      }).catch(() => {
        this.emMassaLoader = false;
        this.quantidadeArquivos = 0;
        this.quantidadeArquivosLoop = 0;
        this.lastPage = 0;
        this.$toast.error('Erro inesperado. Tente novamente');
      });
    },
    async pegarLinks(inicio = null, final = null) {
      let token = localStorage.getItem("token");
      let plataforma = localStorage.getItem("plataforma");
      let url = '';
      let quantidadeRequisicoes = this.lastPage;

      if (inicio && final) {
        url = `${this.VUE_APP_URL_API_DASHBOARD}/api/link-certificados/${plataforma}?inicio=${inicio}&final=${final}&`
      } else {
        url = `${this.VUE_APP_URL_API_DASHBOARD}/api/link-certificados/${plataforma}?`
      }

      this.$toast.info(`O processo pode demorar um pouco, não feche a página`);

      for (let i = 1; i <= quantidadeRequisicoes; i++) {
        var options = {
          method: 'GET',
          url: url + `page=${i}`,
          headers: {
            Authorization: "Bearer " + token,
          }
        };

        await this.axios.request(options).then((response) => {
          this.certificadosDownload.push(...response.data.url);
          this.quantidadeArquivos -= response.data.gerados;

          if (this.certificadosDownload.length == this.quantidadeArquivosLoop) {
            this.baixarCertificados(this.certificadosDownload)
          }

        }).catch(() => {
          this.emMassaLoader = false;
          this.quantidadeArquivos = 0;
          this.quantidadeArquivosLoop = 0;
          this.lastPage = 0;
          this.certificadosDownload = [];
          this.$toast.error('Erro inesperado. Tente novamente');
        });

      }
    },
    async baixarCertificados(certificados) {
      const zip = new JSZip();

      if (this.quantidadeArquivos === 0) {
        this.quantidadeArquivos = 'Baixando arquivo';
      }

      const promises = certificados.map(certificado => {
        var key = certificado.replace('https://certificados-lms.s3.us-west-2.amazonaws.com/', '');

        return new Promise((resolve, reject) => {
          s3.getObject({ Bucket: 'certificados-lms', Key: key }, function (err, data) {
            if (err) {
              reject(err);
            } else {
              zip.file(key, data.Body);
              resolve();
            }
          });
        });
      });

      Promise.all(promises)
        .then(() => {
          zip.generateAsync({ type: 'blob' })
            .then(content => {
              saveAs(content, 'certificados.zip');
              this.quantidadeArquivos = 0;
              this.emMassaLoader = false;
            })
            .catch(() => {
              this.emMassaLoader = false;
              this.quantidadeArquivos = 0;
              this.quantidadeArquivosLoop = 0;
              this.lastPage = 0;
              this.certificadosDownload = [];
              //console.error(error);
            });
        })
        .catch(() => {
          this.emMassaLoader = false;
          this.quantidadeArquivos = 0;
          this.quantidadeArquivosLoop = 0;
          this.lastPage = 0;
          this.certificadosDownload = [];
          //console.error(error);
        });
    },
  },
};
</script>

<style scoped>
#student {
  color: black;
}

.input-search {
  padding: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #888888;
  border-radius: 5px;
  color: black;
}

.div-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-apply {
  background: #fe4a4a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  border: none;
  padding: 5px;
}
</style>