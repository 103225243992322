<template>
  <div v-if="loadUsers" class="text-center">
    <div class="spinner-grow text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loadUsers" id="table-student">
    <table class="table caption-top table-hover table-bordered" id="my-table">
      <caption>
        <div class="row">
          <div class="col">Lista de Usuarios</div>
          <div class="col">
            <div class="col">
              <div class="div-date">
                <div>
                  <label for="">De</label>
                  <input class="input-field" type="date" v-model="startDate" placeholder="dd-mm-yyyy" min="1997-01-01"
                    max="2030-12-31" />
                </div>
                <div>
                  <label for="">Até</label>
                  <input class="input-field" type="date" v-model="endDate" placeholder="dd-mm-yyyy" min="1997-01-01"
                    max="2030-12-31" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="listPerDate(1)">Aplicar</button>
          </div>
          <div class="col-1" v-if="startDate && endDate">
            <button class="btn-apply" @click="clearFilterDate(1)">
              Limpar
            </button>
          </div>
          <div class="col text-end">
            <input type="text" class="input-search" placeholder="Digite seu Filtro " v-model="nameSearch" />
          </div>
        </div>
      </caption>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Documento</th>
          <th scope="col">Dependente</th>
          <th v-if="(tipoIntegracao == 1 && permExcluir == true)" scope="col">Opções</th>
          <th scope="col">Titular</th>
        </tr>
      </thead>
      <tbody v-if="!loadFilter">
        <tr v-for="user in users" :key="user.id">
          <th scope="row">
            <i :class="'bi-person'" style="font-size: 15px; color: #fe4a4a"></i>
          </th>
          <td>{{ user.nome ? user.nome : "Não registrado" }}</td>
          <td>{{ user.email ? user.email : "Não registrado" }}</td>
          <td v-if="user.documento">

            {{
              user.documento.length == 11
              ? formataCPF(user.documento)
              : formataCNPJ(user.documento)
            }}
          </td>
          <td v-else>
            {{
              user.username.length == 11
              ? formataCPF(user.username)
              : formataCNPJ(user.username)
            }}
          </td>
          <td>
            <div class="text-center col-dep" :class="user.titular == null ? 'tit' : 'dep'">
              <span v-if="user.titular == null">
                Não
              </span>
              <span v-else-if="user.titular != null">
                Sim
              </span>
            </div>
          </td>
          <td v-if="(tipoIntegracao == 1 && permExcluir == true)"
            class="d-flex align-items-center justify-content-around">
            <div @click="editarCadastro(user.id)" style="cursor: pointer">
              <i class="bi bi-pencil" style="color: rgb(14, 175, 76)"></i>
            </div>
            <!-- <div @click="confirmarExclusao(user.id)" style="cursor: pointer">
              <i class="bi bi-trash" style="color: #fe4a4a"></i>
            </div> -->
          </td>
          <td>{{ user.titular ? user.titular : 'Não possui' }}</td>
        </tr>
      </tbody>
      <div v-if="loadFilter" class="spinner-border text-danger mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </table>
    <nav aria-label="..." v-if="!loadFilter">
      <ul class="pagination pagination-sm justify-content-center">
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff" @click="getUsers(1)">Primeira</a>
        </li>
        <li class="page-item" :class="current_page == 1 ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff"
            @click="getUsers(current_page - 1)">Anterior</a>
        </li>
        <li v-for="index in last_page" v-show="current_page == index ||
          current_page == index - 1 ||
          current_page == index - 2 ||
          current_page == index - 3 ||
          current_page == index + 1 ||
          current_page == index + 2 ||
          current_page == index + 3
          " :key="index" :class="index == current_page ? 'active' : ''" class="page-item" aria-current="page">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff" @click="getUsers(index)">{{ index }}</a>
        </li>
        <li class="page-item" :class="current_page == last_page ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff"
            @click="getUsers(current_page + 1)">Próximo</a>
        </li>
        <li class="page-item" :class="current_page == last_page ? 'disabled' : ''">
          <a class="page-link" style="background-color: #fe4a4a; color: #fff" @click="getUsers(last_page)">Último</a>
        </li>
      </ul>
    </nav>
    <div class="row">
      <div v-if="loadUsersExport" class="spinner-border text-danger mt-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="col" v-if="!loadUsersExport">
        <div class="d-flex" style="gap: 5px">
          <button v-if="!userExport" class="btn btn-outline-danger" @click="getUsersExport()">
            Exportar
          </button>

          <button class="btn btn-outline-danger ml-5" @click="getUsersExportPdf()">
            <div v-if="loadUsersExportPdf" class="spinner-border text-danger" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>

            <div v-else>
              Exportar como PDF
            </div>
          </button>
        </div>
        <div class="col" id="bnt-excel" v-if="userExport">
          <vue-excel-xlsx :data="data" :columns="columns" :filename="'Lista de Usuarios'" :sheetname="'sheetname'">
            <i class="bi bi-cloud-download" style="font-size: 20px"></i>
            Download
          </vue-excel-xlsx>
        </div>
      </div>
    </div>

    <modal-delete v-if="mostrarModal" :idUser="idUser" @close="mostrarModal = false" @deletar="excluirCadastro" />
  </div>

  <div v-if="(tipoIntegracao == 1 && permExcluir == true)" class="position-relative">
    <div id="alert-botao-excluir" style="position: absolute; top: 50px; width: 100%;">
      <div class="alert alert-primary d-flex align-items-center mb-5" role="alert">        
        <svg width="20px" xmlns="http://www.w3.org/2000/svg" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </svg>
        <div style="color: #222;">
          Descontinuamos o botão “Excluir” em 08/09/2023. Em caso de dúvidas, <strong><a style="color: #222;" href="https://api.whatsapp.com/send?phone=553537297244" target="_blank">fale conosco</a></strong>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDelete from "../modalStudent/modalDelete.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    ModalDelete,
  },
  data() {
    return {
      urlConsult: "",
      loadUsers: false,
      userExport: false,
      loadUsersExport: false,
      users: null,
      last_page: null,
      current_page: null,
      nameSearch: null,
      count: 1500,
      timeOut: null,
      loadFilter: false,
      tipoIntegracao: 0,
      startDate: null,
      endDate: null,
      permExcluir: true,
      columns: [{
        label: "Nome",
        field: "name",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "Documento",
        field: "document",
      },
      {
        label: "Dependente",
        field: "depentent",
      },
      {
        label: "Titular",
        field: "headline",
      },
      {
        label: "Ativo",
        field: "status"
      }
      ],
      data: [],
      mostrarModal: false,
      idUser: null,
      loadUsersExportPdf: false,
    };
  },
  created() {
    this.getUsers(1);
    this.getTipoIntegracao();
  },
  watch: {
    nameSearch() {
      clearTimeout(this.timeOut);
      this.loadFilter = true;
      this.timeOut = setTimeout(() => {
        if (this.nameSearch) {
          this.data = [];
          this.getUser(this.nameSearch);
          this.loadFilter = false;
        } else {
          this.urlConsult = "";
          this.data = [];
          this.getUsers(1);
          this.loadFilter = false;
        }
      }, this.count);
    },
  },
  methods: {
    formataCPF(cpf) {
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, "");

      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    formataCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]/g, "");

      return cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    },
    async getUsersExport() {
      this.data = [];
      this.loadUsersExport = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url;
      if (this.urlConsult) {
        url = this.urlConsult;
        url = url.replace("?page=1", "");
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/users-export/" +
          plataforma;
      }

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data;

          data.forEach((element) => {
            this.data.push({
              name: element.nome,
              email: element.email,
              document: element.documento,
              depentent: element.titular ? 'Sim' : 'Não',
              headline: element.titular ? element.titular : 'Não possui',
              status: element.status == 1 ? 'Sim' : 'Não'
            });
          });

          this.loadUsersExport = false;
          this.userExport = true;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsersExport = false;
        });
    },

    async listPerDate(page) {
      this.data = [];
      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.userExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/users/" +
        plataforma +
        "/" +
        this.startDate +
        "/" +
        this.endDate +
        "?page=" +
        page;

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },

    async clearFilterDate(page) {
      this.startDate = null;
      this.endDate = null;

      this.nameSearch = "";

      this.userExport = false;
      this.data = [];

      this.urlConsult = "";

      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/users/" +
        plataforma +
        "?page=" +
        page;

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },
    async getUsers(page) {
      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.userExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/users/" + plataforma + "?page=" + page;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          // let plataforma = JSON.parse(localStorage.getItem("plataforma"));

          // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
          // let plataformas = JSON.parse(localStorage.getItem("plataformas"));
          // let nomePlataforma = "";
          // plataformas.forEach((element) => {
          //   if (element.id == plataforma) {
          //     nomePlataforma = element.titulo;
          //   }
          // });

          // // Convertendo objeto nomePlataforma para string
          // nomePlataforma = JSON.stringify(nomePlataforma);
          // // Removendo aspas duplas e {} do nome da plataforma
          // nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
          // nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
          // // Removendo a palavra titulo do nome da plataforma
          // nomePlataforma = nomePlataforma.replace("titulo", "");
          // // Tirando : do nome da plataforma
          // nomePlataforma = nomePlataforma.replace(":", "");

          // Comentado pois oculta os usuários que tem CPF = null, mas é necessário exibir todos
          // this.users = this.users.filter((user) => {
          //   return user.documento != null;
          // }); 

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },
    async getUser(filter) {
      this.loadUsers = true;
      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      this.userExport = false;

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/users/" +
        plataforma +
        "/" +
        filter +
        "?page=1";

      this.urlConsult = url;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.last_page = response.data.last_page;
          this.current_page = response.data.current_page;
          this.users = data;

          // let plataforma = JSON.parse(localStorage.getItem("plataforma"));

          // // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
          // let plataformas = JSON.parse(localStorage.getItem("plataformas"));
          // let nomePlataforma = "";
          // plataformas.forEach((element) => {
          //   if (element.id == plataforma) {
          //     nomePlataforma = element.titulo;
          //   }
          // });

          // // Convertendo objeto nomePlataforma para string
          // nomePlataforma = JSON.stringify(nomePlataforma);
          // // Removendo aspas duplas e {} do nome da plataforma
          // nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
          // nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
          // // Removendo a palavra titulo do nome da plataforma
          // nomePlataforma = nomePlataforma.replace("titulo", "");
          // // Tirando : do nome da plataforma
          // nomePlataforma = nomePlataforma.replace(":", "");

          // Comentado pois oculta os usuários que tem CPF = null, mas é necessário exibir todos
          // this.users = this.users.filter((user) => {
          //   return user.documento != null;
          // }); 

          this.loadUsers = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loadUsers = false;
        });
    },
    editarCadastro(idUsuario) {
      if (this.tipoIntegracao == 1) {
        this.$router.replace("/edited-student/" + idUsuario);
      }
    },
    confirmarExclusao(idUsuario) {
      this.idUser = idUsuario;
      this.mostrarModal = true;
    },
    async excluirCadastro(idUserDeleted) {
      console.log(idUserDeleted);
      let token = localStorage.getItem("token");

      let url =
        this.VUE_APP_URL_API_DASHBOARD +
        "/api/reflex/user-delete/" +
        idUserDeleted;
      await this.axios
        .post(url, null, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          console.log(response);
          this.mostrarModal = false;
          this.$toast.success(`Cadastro deletado com sucesso!`);
          this.getUsers(1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTipoIntegracao() {
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));

      let plataforma = localStorage.getItem("plataforma");

      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          this.tipoIntegracao = element.tipoIntegracao.tipo_integracao;
        }
        if (
          plataforma == 461 ||
          plataforma == 466 ||
          plataforma == 467 ||
          plataforma == 472 ||
          plataforma == 491 ||
          plataforma == 492 ||
          plataforma == 495 ||
          plataforma == 496 ||
          plataforma == 497 ||
          plataforma == 498 ||
          plataforma == 499 ||
          plataforma == 539 ||
          plataforma == 571 ||
          plataforma == 572 ||
          plataforma == 573 ||
          plataforma == 586 ||
          plataforma == 591 || // Prefeitura Itatiba
          plataforma == 600 ||
          plataforma == 614 ||
          plataforma == 622
        ) {
          this.permExcluir = false;
        }
      });
    },

    // Gerador de PDF
    async getUsersExportPdf() {
      this.loadUsersExportPdf = true;

      let token = localStorage.getItem("token");
      let plataforma = JSON.parse(localStorage.getItem("plataforma"));

      // Pega o o nome da plataforma no localstorage que tem o id igual plataforma
      let plataformas = JSON.parse(localStorage.getItem("plataformas"));
      let nomePlataforma = "";
      plataformas.forEach((element) => {
        if (element.id == plataforma) {
          nomePlataforma = element.titulo;
        }
      });

      // Convertendo objeto nomePlataforma para string
      nomePlataforma = JSON.stringify(nomePlataforma);
      // Removendo aspas duplas e {} do nome da plataforma
      nomePlataforma = nomePlataforma.replace(/['"]+/g, "");
      nomePlataforma = nomePlataforma.replace(/[{}]+/g, "");
      // Removendo a palavra titulo do nome da plataforma
      nomePlataforma = nomePlataforma.replace("titulo", "");
      // Tirando : do nome da plataforma
      nomePlataforma = nomePlataforma.replace(":", "");

      let url;
      let data;

      if (this.urlConsult) {
        url = this.urlConsult;
        url = url.replace("?page=1", "");
      } else {
        url =
          this.VUE_APP_URL_API_DASHBOARD +
          "/api/reflex/users-export/" +
          plataforma;
      }

      await this.axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          data = response.data;
        });

      // Exportando tabela usando o jsPDF e autoTable
      let doc = new jsPDF("p", "pt", "a4");
      doc.setFontSize(18);
      doc.text(`Relatório de usuários | ${nomePlataforma}`, 40, 85);
      doc.addImage(
        "https://lms-file-upload.s3.us-west-2.amazonaws.com/provedores/logos/20221007011553-leveduraAtivo%2014%403x.png",
        "PNG",
        40,
        25,
        165,
        32,
      );

      //Criando a estrutura da tabela
      let columns = [{
        title: "Nome",
        dataKey: "nome"
      },
      {
        title: "Documento",
        dataKey: "documento"
      },
      {
        title: "Dependente",
        dataKey: "usuarios_extras"
      },
      {
        title: "Titular",
        dataKey: "titular"
      },
      {
        title: "Ativo",
        dataKey: "status"
      }
      ];

      // Formatando dados do array para o formato da tabela
      let rows = [];
      data.forEach((element) => {
        let row = {
          nome: element.nome,
          documento: element.documento,
          usuarios_extras: element.titular != null ? 'Sim' : 'Não',
          titular: element.titular != null ? element.titular : 'Não possui',
          status: element.status == 1 ? 'Sim' : 'Não'
        };
        rows.push(row);
      });

      doc.autoTable(columns, rows, {
        headStyles: {
          fillColor: [255, 77, 77],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },

        margin: {
          top: 100
        },
      });

      // Cria uma slug do nome da plataforma para o nome do arquivo
      let slug = nomePlataforma
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      doc.save(`relatorio-de-usuarios-${slug}.pdf`);
      this.loadUsersExportPdf = false;
    }
  },
};
</script>

<style scoped>
#student {
  color: black;
}

.input-search {
  padding: 5px;
  text-align: left;
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #888888;
  border-radius: 5px;
  color: black;
}

.col-dep {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: auto;
  border-radius: 10px;
  color: white;
  -webkit-box-shadow: 10px 10px 15px -2px rgba(0, 0, 0, 0.52);
  box-shadow: 10px 10px 15px -2px rgba(0, 0, 0, 0.52);
}

.tit {
  background-color: #fe4a4a;
}

.dep {
  background-color: rgb(8, 139, 8);
}

.input-field {
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #888888;
  border-radius: 5px;
}

.btn-apply {
  background: #fe4a4a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-left: 10px;
  color: white;
  border: none;
  padding: 5px;
}

.div-date {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>