<template>
  <div>
    <h2>Rota nao encontrada</h2>
    <h3>Você será redirecionado</h3>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    setTimeout(function () {
      window.location.href = '/'
    }, 2000);
  },
};
</script>