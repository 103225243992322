<template>
  <div id="table-clube">
    <TableClube />
  </div>
</template>

<script>
import TableClube from "./table-clube.vue";
export default {
  data() {
    return {};
  },
  components: {
    TableClube,
  },
};
</script>

<style scoped>
</style>