<template>
  <div id="menu">
    <side-menu> 
      <router-view/>  
    </side-menu>
  </div>
</template>

<script>
import SideMenu from "../components/menu/sideMenu.vue";
export default {
  components: {
    SideMenu,
  },
};
</script>

<style scoped>
#home {
  color: #000;
}
</style>