<template>
  <div id="editedStudent">
    <p>Editar cadastro do aluno</p>
    <div
      v-if="loadUser"
      class="spinner-border text-danger mt-3"
      role="status"
    ></div>
    <div class="row g-3" v-if="!loadUser">
      <div class="col-12">
        <label for="nome" class="form-label">Nome completo</label>
        <input type="text" id="nome" class="form-control" v-model="nome" />
      </div>
      <div class="col-12">
        <label for="email" class="form-label">Email</label>
        <input type="email" id="email" class="form-control" v-model="email" />
      </div>
      <div class="col-12">
        <label for="documento" class="form-label">Documento</label>
        <input
          type="text"
          id="documento"
          class="form-control"
          v-model="documento"
          disabled
        />
      </div>
      <div class="col-12">
        <label for="senha" class="form-label">Senha</label>
        <input
          type="password"
          id="senha"
          class="form-control"
          v-model="senha"
        />
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="btn btn-outline-primary"
          @click="salvar"
          style="width: 100px"
        >
          <span v-if="!loadSalvar">Salvar</span>

          <div
            v-else
            class="spinner-border text-primary"
            role="status"
            aria-hidden="true"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditedStudents",
  data() {
    return {
      token: "",
      idUsuario: this.$route.params.id,
      nome: "",
      email: "",
      documento: "",
      senha: "",
      plataforma: "",
      tempo: 0,
      contador: 1500,
      emailExistente: 0,
      documentoExistente: 0,
      loadUser: false,
      loadSalvar: false,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async salvar() {
      this.loadSalvar = true;
      this.token = localStorage.getItem("token");
      this.plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let formData = new FormData();
      formData.append("id", this.idUsuario);
      formData.append("name", this.nome);
      formData.append("email", this.email);

      if (this.senha != "") {
        formData.append("password", this.senha);
      }

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/user-update";

      await this.axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.loadSalvar = false;
          this.$toast.success(`Cadastro editado com sucesso!`);
          this.$router.push("/new-students");
        })
        .catch((error) => {
          console.log(error);
          this.loadSalvar = false;
        });
    },
    async getUser() {
      this.loadUser = true;
      this.token = localStorage.getItem("token");

      let url =
        this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/user/" + this.idUsuario;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (
            response.data.user.plataforma !=
            JSON.parse(localStorage.getItem("plataforma"))
          ) {
            this.$router.push("/");
          }
          this.nome = response.data.user.nome;
          this.documento = response.data.user.documento;
          this.email = response.data.user.email;
          this.loadUser = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadUser = false;
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
#editedStudent {
  color: black;
}

.errors {
  color: red;
}
</style>
