<template>
  <div id="table-curso">
    <TableCertificados />
  </div>
</template>

<script>
import TableCertificados from "./table-certificados.vue";
export default {
  data() {
    return {};
  },
  components: {
    TableCertificados,
  },
};
</script>

<style scoped>
</style>