<template>
  <div class="div-principal">
    <div class="div-buttons">
    <span>Para obter seu acesso</span>
    <a href="https://api.whatsapp.com/send/?phone=5535998887383&text=Bem+vindos+a+Academia+do+Provedor%21&app_absent=0" target="_blank"><button class="btn btn-outline-success button-style"><img class="img-style" src="../../assets/logo-wpp.png"/> Fale com o suporte</button></a>
    </div>
    <span style="padding-top:30px">ou</span>
    <div class="div-buttons">
    <span>Se ja tiver acesso</span>
    <a href="https://np-login.academiadoprovedor.com.br/#/login" target="_blank"><button class="btn btn-outline-danger button-style">Acessar Painel Viacom</button></a>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  components: {

  },
};
</script>

<style scoped>
 .div-principal {
     text-align: center;
     display: flex;
     gap: 20px;
     justify-content: center;
     align-items: center;
 }

 .div-buttons{
   display: flex;
   flex-direction: column;
   gap: 10px;
 }
 .button {
     background-color: red;
 }
 .button:hover {
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.img-style{
  width:35px; 
  height:35px; 
  padding-left:3px;
}

.button-style{
  width:202px;
  height:50px; 
}
</style>