<template>
  <div id="table-curso">
    <TableCursos />
  </div>
</template>

<script>
import TableCursos from "./table-cursos.vue";
export default {
  data() {
    return {};
  },
  components: {
    TableCursos,
  },
};
</script>

<style scoped>
</style>