<template>
  <div class="row">
    <div class="col-auto">
      <ul class="side-menu">
        <div>
          <li>
            <div class="sidebar-header">
              <router-link :to="{ path: '/' }">
                <img
                  class="w-80 mt-2"
                  src="../../assets/logo-leveduca.png"
                  alt="Leveduca"
                />
              </router-link>
            </div>
          </li>
          <li>
            <a href="#">
              <router-link :to="{ path: '/new-students' }"
                ><span class="fa fa-user"></span>Alunos</router-link
              ></a
            >
          </li>
           <li>
            <a href="#">
              <router-link :to="{ path: '/progress-users-curso' }"
                ><span class="fa fa-flag-checkered"></span>Progresso por curso</router-link
              ></a
            >
          </li>
          <li>
            <a href="#">
              <router-link :to="{ path: '/progress-users' }"
                ><span class="fa fa-flag-checkered"></span>Progresso por aula</router-link
              ></a
            >
          </li>
          <!-- <li>
            <a href="#">
              <router-link :to="{ path: '/viacom' }"
                ><span class="fa fa-play-circle"></span>Dashboard
                Paramount</router-link
              ></a
            >
          </li> -->
          <li v-if="liberaAreaDoProvedor">
            <a href="#">
              <router-link :to="{ path: '/info' }"
                ><span class="fa fa-info-circle"></span>Área da Empresa</router-link
              ></a
            >
          </li>
          <li>
            <a href="#" style="">
              <router-link :to="{ path: '/list-cursos' }">
                <span class="fa fa-book"></span>Lista de Cursos</router-link
              >
            </a>
          </li>
          <li>
            <a href="#">
              <router-link :to="{ path: '/list-certificados' }"
                ><span class="fa fa-graduation-cap"></span>Lista de Certificados</router-link>
            </a>
          </li>
          <li v-if="beneficio">
            <a href="#">
              <router-link :to="{ path: '/list-clube' }"
                ><span class="fa fa-rectangle-list"></span>Leveduca Vantagens</router-link>
            </a>
          </li>
          
          <li v-for="(user, index) in usersAccess" :key="index">
            <a href="#" v-if="user == this.user.id">
              <router-link :to="{ path: '/report' }">
                <span class="bi bi-clipboard-data"></span>Gerar Relatório
              </router-link>
            </a>
          </li>
          <li v-if="tipoIntegracao == 1 && user != '169730'">
            <a href="#">
              <router-link :to="{ path: '/registered-student' }">
                <span class="fa fa-plus"></span>Cadastro de Alunos
              </router-link>
            </a>
          </li>
        </div>

        <div class="position-absolute bottom-0">
          <li @click="logout">
            <a href="#" style="color: black">
              <span class="fa fa-sign-out"></span>Sair</a
            >
          </li>
        </div>
      </ul>
    </div>

    <div class="col plataformas">
      <div
        v-if="loadOptionsPlataformas"
        class="spinner-border text-danger"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="row plataforma" v-if="plataformas.length <= 1">
        <span style="color: black">{{ nomePlataforma }}</span>
      </div>
      <div v-if="plataformas.length > 1" class="pt-2">
        <select
          v-if="!loadOptionsPlataformas"
          v-model="selectedPlataforma"
          name="selectCountry"
          class="input-form-select"
        >
          <option
            v-for="plataforma in plataformas"
            :key="plataforma.id"
            :value="plataforma.id"
          >
            {{ plataforma.titulo ? plataforma.titulo.titulo : "" }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="col-auto container-col">
    <div class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadOptionsPlataformas: false,
      selectedPlataforma: null,
      showSideMenu: false,
      user: null,
      liberaAreaDoProvedor: true,
      plataformas: [],
      tipoIntegracao: 0,
      usersAccess: [143, 492, 147, 75],
      beneficio: false,
    };
  },
  async created() {
    this.user = this.me();
    await this.getPlataformas();
    await this.getBeneficio();
  },
  watch: {
    selectedPlataforma(new_value, old_value) {
      localStorage.setItem("plataforma", this.selectedPlataforma);
      if (old_value == null) {
        return;
      }
      this.$router.go();
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    me() {
      return JSON.parse(localStorage.getItem("user"));
    },
    async getPlataformas() {
      this.loadOptionsPlataformas = true;

      this.plataformas = JSON.parse(localStorage.getItem("plataformas"));
      
      // Colocando as plataformas em ordem alfabética por titulo
      this.plataformas.sort((a, b) => {

        // Se o titulo for null remove ele da lista
        if (a.titulo == null || b.titulo == null) {
          return 1;
        }

        if (a.titulo.titulo < b.titulo.titulo) {
          return -1;
        }
        if (a.titulo.titulo > b.titulo.titulo) {
          return 1;
        }
        
        return 0;
      });

      let plataforma = localStorage.getItem("plataforma");
      
      // A pedido da Yasmin , medida paleativa por hora para ocular o campo Area do Provedor
      if (plataforma === '344' || plataforma === '346'){
        this.liberaAreaDoProvedor = false;
      }

      this.plataformas.forEach((element) => {
        if (element.id == plataforma) {
          this.tipoIntegracao = element.tipoIntegracao.tipo_integracao;
        }
      });

      this.selectedPlataforma = plataforma;

      this.loadOptionsPlataformas = false;
    },
    async getBeneficio(){
      let token = localStorage.getItem("token");
      let plataforma = localStorage.getItem("plataforma");

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/" + plataforma;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          this.beneficio = response.data.beneficio;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    nomePlataforma() {
      if (this.plataformas[0] != null) {
        if (this.plataformas[0].titulo) {
          return this.plataformas[0].titulo.titulo;
        }
      }

      return "Provedor";
    },
  },
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/css?family=Roboto";
.side-menu {
  font-family: "Roboto", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 85px;
  list-style-type: none;
  background: #fe4a4a;
  overflow: hidden;
  transition: width 0.5s;
  z-index: 1;
}

.side-menu:hover {
  width: 250px;
}

.side-menu li {
  width: 250px;
}

.side-menu li a span {
  font-size: 1rem;
  margin: 20px 30px 0 22px;
  padding-left: 20px;
}

.side-menu li a {
  display: block;
  font-size: 0.9rem;
  text-decoration: none;
  color: #fff;
  height: 60px;
}

.side-menu li a:hover {
  background: #fff;
  color: #000;
  display: block;
}

.side-menu li:first-child a {
  background: #fff;
  display: block;
}

.container-col {
  margin-top: 30px;
  margin-left: 150px;
  background-color: #f1f1f183;
  width: 80vw;
  height: auto;
  border-radius: 10px;
  -webkit-box-shadow: 5px 5px 13px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 5px 5px 13px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  margin-bottom: 20px;
}
.plataformas {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 60px;
}
.input-form-select {
  width: 150px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #888888;
  border-radius: 5px;
  color: #2a2a2a;
}
</style>