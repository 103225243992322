
<template>
  <div id="student">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="register ? 'active' : ''"
          aria-current="page"
          @click="controlRegister"
          >Registrar aluno</a
        >
      </li>
      <li v-if="userLogin == '124099'" class="nav-item">
        <a
          class="nav-link"
          aria-current="page"
          :class="registers ? 'active' : ''"
          @click="controlRegisters"
          >Registrar lista de alunos por CSV</a
        >
      </li>
    </ul>
    <div class="row g-3 mt-5" v-if="register">
      <div class="col-12">
        <label for="nome" class="form-label">Nome completo*</label>
        <input type="text" id="nome" class="form-control" v-model="nome" />
        <small v-html="erros.name" class="errors"></small>
      </div>
      <div class="col-12">
        <label for="email" class="form-label">Email*</label>
        <input type="email" id="email" class="form-control" v-model="email" />
        <small v-html="erros.email" class="errors"></small>
        <small v-if="this.emailExistente == 1" class="errors"
          >Este e-mail já esta em uso</small
        >
      </div>
      <div class="col-12">
        <label for="documento" class="form-label">Documento*</label>
        <small class="d-block"> <strong>*somente números</strong></small>
        <input
          type="text"
          id="documento"
          class="form-control"
          v-model="documento"
        />
        <small v-html="erros.document" class="errors"></small>
        <small v-if="this.documentoExistente == 1" class="errors"
          >Este documento já esta em uso</small
        >
        <small
          v-if="this.documentoValido && documento != ''"
          style="color: green"
          >Documento válido</small
        >
        <small v-if="!this.documentoValido && documento != ''" class="errors"
          >Documento inválido</small
        >
      </div>
      <div class="col-12">
        <label for="senha" class="form-label">Senha*</label>
        <input
          type="password"
          id="senha"
          class="form-control"
          v-model="senha"
        />
        <small v-html="erros.password" class="errors"></small>
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="btn btn-outline-primary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Todos os campos devem ser preenchidos"
          @click="salvar"
          style="width: 100px"
          :disabled="
            nome == '' || email == '' || !documentoValido || senha == ''
          "
        >
          <span v-if="!loadSalvar">Cadastrar</span>

          <div
            v-else
            class="spinner-border text-primary"
            role="status"
            aria-hidden="true"
          ></div>
        </button>
        
      </div>
      <div>
        <small
          v-if="limiteCadastro"
          style="color: red"
          >Não foi possível cadastrar o usuário. Todas as licenças ja foram consumidas!</small
        >
      </div>
    </div>

    <div class="row g-3 mt-5" v-if="registers">
      <div class="col-12">
        <div>
          <h4><strong>ATENÇÃO</strong> - NÃO INSERIR MAIS USUÁRIOS QUE O LIMITE DE LICENÇAS</h4><br>
          <label for="formFileLg" class="form-label">Carregar lista</label>
          <input
            class="form-control form-control-lg"
            id="formFileLg"
            type="file"
            accept=".csv"
            @change="setRegisterCsv"
          />
        </div>
      </div>
      <div class="col-12">
        <button
          type="submit"
          class="btn btn-outline-primary"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Todos os campos devem ser preenchidos"
          @click="salvarCsv"
          style="width: 100px"
          :disabled="!registerCsv"
        >
          <span v-if="!loadSalvarCsv">Cadastrar</span>

          <div
            v-else
            class="spinner-border text-primary"
            role="status"
            aria-hidden="true"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registerCsv: null,
      register: false,
      registers: false,
      token: "",
      nome: "",
      email: "",
      documento: "",
      senha: "",
      plataforma: "",
      tempo: 0,
      contador: 1500,
      erros: [],
      emailExistente: 0,
      documentoExistente: 0,
      loadSalvar: false,
      loadSalvarCsv: false,
      documentoValido: false,
      userLogin: "",
    };
  },
  created() {
    this.getUsuerLogin();
  },
  methods: {
    getUsuerLogin(){
      this.userLogin = JSON.parse(localStorage.getItem("user"));
    },
    setRegisterCsv(e) {
      this.registerCsv = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(this.registerCsv);
    },
    controlRegister() {
      this.register = !this.register;
      this.registers = false;
    },
    controlRegisters() {
      this.registers = !this.registers;
      this.register = false;
    },
    async salvarCsv() {
      this.loadSalvarCsv = true;
      this.token = localStorage.getItem("token");
      this.plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let formData = new FormData();

      formData.append("file", this.registerCsv);
      formData.append('plataforma', this.plataforma)

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/users-import";

      await this.axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.registerCsv = null;
          this.loadSalvarCsv = false;
          this.$toast.success(`Lista cadastrada com sucesso!`);
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.erros = error.response.data.errors;
            this.loadSalvarCsv = false;
          }
          this.$toast.error(
            `Erro ao carregar a lista verifique se foi preenchida corretamente ou algum usuario já cadastrado!`
          );
          this.loadSalvarCsv = false;
        });
    },
    async salvar() {
      this.loadSalvar = true;
      this.token = localStorage.getItem("token");
      this.plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let formData = new FormData();

      formData.append("name", this.nome);
      formData.append("email", this.email);
      formData.append("document", this.documento);
      formData.append("password", this.senha);
      formData.append("plataforma", this.plataforma);
     
      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/user";

       await this.axios
        .post(url, formData, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          console.log(response);
          (this.nome = ""),
            (this.email = ""),
            (this.documento = ""),
            (this.senha = ""),
            (this.loadSalvar = false);
          this.$toast.success(`Cadastro realizado com sucesso!`);
        })
        .catch((error) => {
          
          if (error.response.data.errors) {
            this.erros = error.response.data.errors;
            this.loadSalvar = false;
          }
          if (error.response.data.error == 500){
            this.loadSalvar = false;
            this.limiteCadastro = true;
          }
        });
        // document.location.reload(true);
      
      
    },
    async getEmails(email) {
      this.token = localStorage.getItem("token");
      this.plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url = this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/email/" + this.plataforma + '/' + email;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.emailExistente = response.data;
        })
        .catch(() => {});
    },
    async getDocumentos(documento) {
      this.token = localStorage.getItem("token");
      this.plataforma = JSON.parse(localStorage.getItem("plataforma"));

      let url =
        this.VUE_APP_URL_API_DASHBOARD + "/api/reflex/document/" + this.plataforma + '/' + documento;

      await this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.documentoExistente = response.data;
          if (this.documentoExistente != 1) {
            var tamanhoDocumento = documento.length;

            if (tamanhoDocumento === 11) {
              this.documentoValido = this.validarCPF(documento);
            } else if (tamanhoDocumento === 14) {
              this.documentoValido = this.validarCNPJ(documento);
            } else {
              this.documentoValido = false;
            }
          }
        })
        .catch(() => {});
    },
    validarCPF(inputCPF) {
      var soma = 0;
      var i;
      var resto;

      if (inputCPF == "00000000000") return false;
      for (i = 1; i <= 9; i++)
        soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(inputCPF.substring(9, 10))) return false;

      soma = 0;
      for (i = 1; i <= 10; i++)
        soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
      resto = (soma * 10) % 11;

      if (resto == 10 || resto == 11) resto = 0;
      if (resto != parseInt(inputCPF.substring(10, 11))) return false;
      return true;
    },
    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      var i;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
  },
  watch: {
    email() {
      clearTimeout(this.tempo);
      this.tempo = setTimeout(() => {
        if (this.email != "") {
          this.getEmails(this.email);
        }
      }, this.contador);
    },
    documento() {
      clearTimeout(this.tempo);
      this.tempo = setTimeout(() => {
        if (this.documento != "") {
          this.getDocumentos(this.documento);
        }
      }, this.contador);
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
#student {
  color: black;
}

.errors {
  color: red;
}
</style>