<template>
  <span>
    <span class="container-tooltip">
       
      <span class="tooltip1">
       <slot />
        
        <span class="tooltip1text paragrafo-mini">{{ this.descricao }}</span>
        
    </span>
    </span>
  </span>
  
</template>

<script>
export default {
  name: "tooltip",
  props: {
    descricao: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.container-tooltip {
  display: inline-block;
  /* background: purple; */
    
}
.tooltip1 {
  /* margin-top:7px; */
  
  position: relative;
  display: flex;
  
  
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px dotted #EE494B;
  border: 2px solid #EE494B; */
  /* border-radius: 50%; */
  /* width: 1.4rem; 
  height: 1.4rem; */
  /* font-weight: bold; */

}

.tooltip1 .tooltip1text {
  visibility: hidden;
  min-width: 100px;
  max-height: 50px;
  background-color: #EE494B;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  font-weight: bold;
  

}

.tooltip1 .tooltip1text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.tooltip1:hover .tooltip1text {
  visibility: visible;
}
</style>