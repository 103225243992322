import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIAXMQ3KOLJEANO5NXZ',
    secretAccessKey: '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',
    region: 'us-west-2'
});

const s3 = new AWS.S3();

export default s3;